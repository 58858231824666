<!--
 * @Description: 项目负责人
 * @Author: 琢磨先生
 * @Date: 2023-02-18 21:33:47
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-01-20 09:24:33
-->
<template>
  <el-card class="box query">
    <el-form
      :model="query"
      ref="query"
      :inline="true"
      @submit.prevent="onSearch"
    >
      <el-form-item label="楼盘">
        <el-input
          v-model="query.q"
          placeholder="楼盘名称"
          clearable
          style="width: 300px"
        ></el-input>
      </el-form-item>
      <el-form-item label="负责人">
        <el-input
          v-model="query.fzr_q"
          placeholder="姓名 手机号"
          clearable
          style="width: 200px"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          round
          @click="onSearch"
          icon="search"
          native-type="submit"
          >查询</el-button
        >
      </el-form-item>
    </el-form>
    <div>
      <!-- <add-user v-if="is_can_edit" @success="loadData"></add-user> -->
    </div>
  </el-card>
  <el-card class="box data" v-loading="loading">
    <el-table :data="tableData.data" border stripe>
      <el-table-column label="楼盘名称" width="340" prop="estateName">
        <template #default="scope">
          {{ scope.row.estateName }}
          <span
            :style="`color: var(--el-color-${
              scope.row.estateStopStatus == 0 ? 'success' : 'danger'
            });`"
            >({{ scope.row.estateStopStatusText }})</span
          >
        </template>
      </el-table-column>
      <el-table-column label="负责人" min-width="300">
        <template #default="scope">
          <el-tag
            type="info"
            size="small"
            effect="plain"
            v-for="x in scope.row.fuZeRenList"
            :key="x"
            closable
            @close="onDelete(scope.row, x)"
            style="
              margin-right: 20px;
              color: var(--el-table-text-color);
              border: none;
              border-bottom: 1px dotted var(--el-color-primary);
              border-radius: 0;
              background: transparent;
            "
            >{{ `${x.name}(${x.mobile})` }}
            {{ x.isReceiveSms ? "[接收短信]" : "" }}
          </el-tag>

          <el-button
            type="primary"
            link
            size="small"
            icon="plus"
            @click="onEditFzr(scope.row)"
            >设置</el-button
          >
        </template>
      </el-table-column>
      <el-table-column label="操作" width="150">
        <template #default="scope">
          <!-- <el-button
            type="primary"
            link
            size="small"
            @click="onEdit(scope.row)"
            icon="edit"
            v-if="is_can_edit"
            >修改</el-button
          > -->
          <el-popconfirm
            width="220"
            v-if="is_can_del"
            title="您确定要删除项目全部负责人？"
            @confirm="onClean(scope.row)"
          >
            <template #reference>
              <el-button
                size="small"
                type="danger"
                link
                icon="delete"
                :loading="scope.row.deleting"
                >删除</el-button
              >
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="pageSizeChange"
      @current-change="pageCurrentChange"
      :current-page="query.pageIndex"
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="query.pageSize"
      layout="total, sizes, prev, pager, next"
      :total="tableData.counts"
    ></el-pagination>
  </el-card>
  <edit-fzr
    :item="current_item"
    @closed="current_item = null"
    @success="loadData"
  ></edit-fzr>
</template>

<script>
import editFzr from "./edit_fzr.vue";
export default {
  components: {
    editFzr,
  },
  data() {
    return {
      loading: false,
      query: {
        pageIndex: 1,
        pageSize: 20,
        sort: {},
        q: "",
      },
      tableData: {
        counts: 0,
      },
      current_item: null,
      is_can_edit: false,
      is_can_del: false,
    };
  },
  created() {
    this.is_can_edit = this.$power("admin/v1/estateFzr/edit");
    this.is_can_del = this.$power("admin/v1/estateFzr/del");
    this.loadData();
  },
  methods: {
    /**
     * 加载数据
     */
    loadData() {
      this.current = null;
      this.loading = true;
      this.$http
        .post("admin/v1/estateFzr", this.query)
        .then((res) => {
          if (res.code == 0) {
            this.tableData = res.data;
          }
        })
        .finally(() => (this.loading = false));
    },
    /**
     * 搜索
     */
    onSearch() {
      this.query.pageIndex = 1;
      this.loadData();
    },
    /**
     * 排序
     */
    onSort(e) {
      if (e.prop) {
        this.query.sort = {
          order: e.order,
          prop: e.prop,
        };
        this.loadData();
      }
    },

    /**
     * 分页页数更改
     */
    pageSizeChange(val) {
      this.query.pageSize = val;
      this.loadData();
    },

    /**
     * 分页页码更改
     */
    pageCurrentChange(val) {
      this.query.pageIndex = val;
      this.loadData();
    },

    /**
     * 设置负责人
     */
    onEditFzr(item) {
      this.current_item = item;
    },

    /**
     *
     * @param {*} a
     * @param {*} b
     */
    onDelete(e, c) {
      console.log(c);
      this.$confirm(
        `您确定要删除【${e.estateName}】负责人【${c.name}】？`,
        "温馨提示"
      ).then(() => {
        this.$http
          .get(
            `admin/v1/estateFzr/del?estateId=${e.estateId}&consumerId=${c.fzrConsumerId}`
          )
          .then((res) => {
            if (res.code == 0) {
              this.$message.success(res.msg);
              this.loadData();
            }
          });
      });
    },

    /**
     * 删除项目全部负责人
     */
    onClean(item) {
      item.deleting = true;
      this.$http
        .get(`admin/v1/estateFzr/clean?estateId=${item.estateId}`)
        .then((res) => {
          if (res.code == 0) {
            this.$message.success(res.msg);
            this.loadData();
          }
        });
    },
  },
};
</script>

<style scoped></style>
